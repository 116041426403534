import { ProfileEducation } from './education-student-profile'

export enum StudentSubscriptions {
  CODERBECA = 'CoderBeca',
  STANDARD = 'Standard',
  CODERPLUS = 'CoderPlus',
}

export enum StudentsService {
  CV = 'Armá un CV que destaque',
  LINKEDIN = 'Descubrí el potencial de Linkedin',
  PORTFOLIO = 'Creá un portfolio genial',
  STRATEGY = 'Estrategia de búsqueda laboral',
  INTERVIEW = 'Preparate para tener excelentes entrevistas',
  PROPOSAL = 'Presupuestá y creá propuestas atractivas',
}

export class EducationStudents {
    _id: string
    email: string
    subscription: StudentSubscriptions[]
    activeServices: StudentsService[]
    profile: ProfileEducation
    constructor (_id: string, email: string, subscription: StudentSubscriptions[], activeServices: StudentsService[], profile: ProfileEducation) {
      this._id = _id
      this.email = email
      this.subscription = subscription
      this.activeServices = activeServices
      this.profile = new ProfileEducation(
        profile.firstName,
        profile.lastName,
        profile.onCareers,
        profile.country
      )
    }
}

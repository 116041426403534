
import { defineComponent, ref } from 'vue'
import StudentsTable from '@/views/education/students/components/StudentsTable.vue'
import OverlayPanel from 'primevue/overlaypanel'
import { useClassStudentList } from './hooks/useClassStudentList'
import ScrollPanel from 'primevue/scrollpanel'
import LoadingScreen from '@/components/commons/LoadingScreen.vue'
import { EducationStudents } from '@/models/education-students/classes/education-student'
export default defineComponent({
  components: {
    StudentsTable,
    LoadingScreen,
    OverlayPanel,
    ScrollPanel
  },
  setup () {
    const {
      firstVal,
      loading,
      allCoursePlans,
      filters,
      performanceFilters,
      selectedCustomFilter,
      students,
      totalRecords,
      isFiltered,
      searchTxt,
      coursesQtyNumber,
      displayCreateFilter,
      toggleCreateFilter,
      onPage,
      onSort,
      recordsLimit,
      updateFilterListened,
      filterNameInput,
      saveFilters,
      showConfirmDeleteFilter,
      cleanAndUpdateFiltersBySelector,
      removeActiveFilter,
      updateSearchTxt,
      allColumns,
      selectedColumns,
      changeSelectedColumns,
      loadingPaginator,
      countError
    } = useClassStudentList()
    const op = ref()
    const toggle = (event: Event) => {
      op.value.toggle(event)
    }
    const selectedRows = ref<EducationStudents[]>([])
    const showCol = ref<string[]>(['Nombre', 'Comisiones', 'Carrerino', 'Categorías', 'Servicios activos'])
    const emailModal = ref<boolean>(false)
    const showEmailModal = () => {
      emailModal.value = !emailModal.value
    }
    return {
      showEmailModal,
      emailModal,
      clickCheck: (selectedCheck: any) => {
        if (showCol.value.find(element => element === selectedCheck.key)) {
          showCol.value = []
        }
        showCol.value.push(selectedCheck)
      },
      addSelectedRow: (row: any) => {
        if (selectedRows.value.find(e => e === row)) {
          selectedRows.value = selectedRows.value.filter(e => e !== row)
        } else {
          selectedRows.value.push(row)
        }
      },
      showCol,
      selectedRows,
      op,
      toggle,
      firstVal,
      loading,
      allCoursePlans,
      filters,
      performanceFilters,
      selectedCustomFilter,
      students,
      totalRecords,
      isFiltered,
      searchTxt,
      coursesQtyNumber,
      displayCreateFilter,
      toggleCreateFilter,
      onPage,
      onSort,
      recordsLimit,
      updateFilterListened,
      filterNameInput,
      saveFilters,
      showConfirmDeleteFilter,
      cleanAndUpdateFiltersBySelector,
      removeActiveFilter,
      updateSearchTxt,
      allColumns,
      selectedColumns,
      changeSelectedColumns,
      loadingPaginator,
      countError,
      changePage: (event: any) => {
        onPage(event)
        selectedRows.value = []
      }
    }
    /* return {
      educationStudents,
      isLoading,
      onPageChange,
      first: 0,
      totalRecords: 120,
      totalRecords2: 12
    } */
  }
})

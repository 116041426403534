import { watch, ref, Ref, onMounted, computed } from 'vue'

import { Country } from '@/models/countries/classes/Country'
import { DataTablePageEvent, DataTableSortEvent } from '@/models/interfaces/primevue'
import { ClassFeedbackSummary } from '@/models/course-feedbacks/summaries/classes/ClassFeedbackSummary'
import { CourseFeedbackSummaryAPI } from '@/api/feedbacks.api'
import { useToast } from 'primevue/usetoast'
import { ToastLife, ToastSeverities, ToastSummaries } from '@/models/components/toast/enums'
import { CourseAPI } from '@/api/course.api'
import { CourseStatus } from '@/models/course/enums'
import { CoursePerformanceFilters, ICoursePerformanceFilters } from '@/models/custom-filters/filters/CoursePerformanceFilters'
import { CourseType } from '@/models/enums'
import { CoursePlan } from '@/models/academic-definitions/classes/CoursePlan'
import { CoursePlanAPI } from '@/api/academic-definitions/course-plan.api'
import { TableName, TypeFilter } from '@/models/custom-filters/enum'
import { CustomFilter, FilterProps } from '@/models/custom-filters/classes/CustomFilter'
import { Observable } from 'rxjs'
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators'
import { useCustomFilters } from './useCustomFilters'
import { ColumnFields } from '@/models/components/tables/enums'
import { Column } from '@/models/components/tables/Column'
import { EducationStudents } from '@/models/education-students/classes/education-student'
import { EducationStudentAPI } from '@/api/education-student.api'

interface useClassStudentList {
  firstVal: Ref<number>
  loading: Ref<boolean>
  allCoursePlans: Ref<CoursePlan[]>
  filters: Ref<CoursePerformanceFilters>
  students: Ref<EducationStudents[]>
  totalRecords: Ref<number>
  coursesQtyNumber: Ref<number>
  searchTxt: Ref<string>
  sortFieldFilter: Ref<string|null>
  sortOrderFilter: Ref<number>
  recordsLimit: number
  performanceFilters: Ref<CustomFilter[]>
  performanceViews: Ref<CustomFilter[]>
  selectedCustomFilter: Ref<CustomFilter | null>,
  selectedCustomView: Ref<CustomFilter | null>,
  displayCreateFilter: Ref<boolean>
  filterNameInput: Ref<string>
  isFiltered: Ref<boolean>
  toggleCreateFilter: () => void
  removeActiveFilter: (type: TypeFilter) => void
  onPage: (e: DataTablePageEvent) => void
  onSort: (e: DataTableSortEvent) => void
  updateFilterListened: (f: FilterProps) => void
  saveFilters: (type: TypeFilter) => Promise<void>
  showConfirmDeleteFilter: (cf: CustomFilter) => void
  cleanAndUpdateFiltersBySelector: (f: CustomFilter) => void
  updateSearchTxt: (val: string) => void
  selectedColumns: Ref<ColumnFields[]>
  allColumns: Map<ColumnFields, Column>
  changeSelectedColumns: (keys: ColumnFields[]) => void
  loadingPaginator: Ref<boolean>
  countError: Ref<boolean>
}

export const useClassStudentList = (): useClassStudentList => {
  // Active filter
  const filters = ref<CoursePerformanceFilters>(new CoursePerformanceFilters())
  const selectedCountries = computed<Country[]>(() => filters.value.countries.value as Country[])
  const selectedCoursePlans = computed<CoursePlan[]>(() => filters.value.coursePlans.value as CoursePlan[])
  const courseTypeFilter = computed<CourseType[]>(() => filters.value.courseType.value as CourseType[])
  const selectedMonth = computed<string>(() => filters.value.startMonth.value as string)
  const selectCarrerino = computed<any[]>(() => filters.value.carrerino.value as any[])
  const selectCategories = computed<string[]>(() => filters.value.categories.value as string[])
  const selectServices = computed<string[]>(() => filters.value.services.value as string[])
  const searchTxt = ref('')
  const updateSearchTxt = (val: string): void => {
    searchTxt.value = val
  }
  const searchTxt$ = new Observable<string>(subscriber => {
    watch(searchTxt, () => subscriber.next(searchTxt.value))
  })

  const allColumns: Map<ColumnFields, Column> = new Map([
    [ColumnFields.ColName, { field: ColumnFields.ColName, header: 'Nombre' }],
    [ColumnFields.ColCarr, { field: ColumnFields.ColCarr, header: 'Carrerino' }],
    [ColumnFields.ColCat, { field: ColumnFields.ColCat, header: 'Categorías' }],
    [ColumnFields.ColSer, { field: ColumnFields.ColSer, header: 'Servicios activos' }]
  ])

  const selectedColumns = ref<ColumnFields[]>(Array.from(allColumns.keys()))

  const changeSelectedColumns = (keys: ColumnFields[]): void => {
    selectedColumns.value = keys
  }

  watch(selectedColumns, () => {
    filters.value.columns.value = selectedColumns.value
  })

  // UI
  const toast = useToast()
  const loading = ref<boolean>(false)
  const loadingPaginator = ref<boolean>(false)
  // Performance table config
  const students = ref<EducationStudents[]>([])
  const firstVal = ref(0)
  const recordsLimit = 10
  const sortFieldFilter = ref<string | null>(null)
  const sortOrderFilter = ref<number>(1)
  const totalRecords = ref<number>(1)
  const allCoursePlans = ref<CoursePlan[]>([])
  const coursesQtyNumber = ref<number>(0)
  const countError = ref<boolean>(false)
  const currentMaxPage = ref<number>(0)

  const loadCount = async () => {
    try {
      loadingPaginator.value = true
      const total = await EducationStudentAPI.getTotal({
        person: encodeURIComponent(searchTxt.value.trim()),
        ...(selectCategories.value && selectCategories.value.length > 0) && { subscriptions: selectCategories.value.map((cat: any) => cat.key) },
        ...(selectServices.value && selectServices.value.length > 0) && { services: selectServices.value.map((serv: any) => serv.key) },
        ...(selectedCountries.value && selectedCountries.value.length > 0) && { countries: selectedCountries.value.map((serv: any) => serv.code) },
        ...(selectCarrerino.value && selectCarrerino.value.length > 0) && { carrerino: selectCarrerino.value[0].key ? 1 : 0 }
      })
      totalRecords.value = total
    } catch (error) {
      countError.value = true
    }
  }

  const updateList = async (changeTotal?: boolean) => {
    try {
      loading.value = true
      const educationStudents = await EducationStudentAPI.getStudents({
        limit: recordsLimit,
        page: firstVal.value + 1,
        person: encodeURIComponent(searchTxt.value.trim()),
        ...(selectCategories.value && selectCategories.value.length > 0) && { subscriptions: selectCategories.value.map((cat: any) => cat.key) },
        ...(selectServices.value && selectServices.value.length > 0) && { services: selectServices.value.map((serv: any) => serv.key) },
        ...(selectedCountries.value && selectedCountries.value.length > 0) && { countries: selectedCountries.value.map((serv: any) => serv.code) },
        ...(selectCarrerino.value && selectCarrerino.value.length > 0) && { carrerino: selectCarrerino.value[0].key ? 1 : 0 }
      })
      students.value = educationStudents.data
      if (changeTotal) {
        countError.value = false
        currentMaxPage.value = 0
        totalRecords.value = 1
        loadingPaginator.value = true
        await loadCount()
      }
      if (countError.value) {
        if (firstVal.value + 1 > currentMaxPage.value) {
          currentMaxPage.value = firstVal.value + 1
          totalRecords.value = totalRecords.value + educationStudents.data.length
        }
      }
    } catch (error) {
      toast.add({
        summary: ToastSummaries.Error,
        detail: 'Ocurrió un error al cargar las comisiones',
        severity: ToastSeverities.Error,
        life: ToastLife.Default
      })
      console.error(error)
    } finally {
      loading.value = false
      loadingPaginator.value = false
    }
  }

  const findAllCoursePlansToSelector = async () => {
    try {
      loading.value = true
      allCoursePlans.value = await CoursePlanAPI.findAll()
    } catch (e) {
      console.error(e)
    } finally {
      loading.value = false
    }
  }

  const {
    findAllCustomFilters, toggleCreateFilter, saveFilters, showConfirmDeleteFilter, removeActiveFilter, updateFilterListened, cleanAndUpdateFiltersBySelector, allCustomFilters, allCustomViews, isFiltered, filterNameInput, selectedCustomFilter, selectedCustomView, displayCreateFilter
  } = useCustomFilters({
    loading: loading,
    tableName: TableName.CoursePerformanceView, // cambiar
    filtersListened: filters,
    cleanFilter: new CoursePerformanceFilters({ columns: filters.value.columns } as ICoursePerformanceFilters) as unknown as FilterProps
  })

  onMounted(async () => {
    updateList(true)
  })

  watch(filters, () => {
    firstVal.value = 0
    updateList(true)
  })

  watch(searchTxt, () => {
    searchTxt$.pipe(
      debounceTime(800),
      distinctUntilChanged(),
      tap(() => (firstVal.value = 0))
    ).subscribe(() => updateList(true))
  })

  return {
    firstVal,
    removeActiveFilter,
    coursesQtyNumber,
    allCoursePlans,
    filters,
    loading,
    students,
    searchTxt,
    totalRecords,
    sortFieldFilter,
    sortOrderFilter,
    filterNameInput,
    recordsLimit,
    performanceFilters: allCustomFilters,
    performanceViews: allCustomViews,
    selectedCustomFilter,
    selectedCustomView,
    displayCreateFilter,
    toggleCreateFilter,
    isFiltered,
    onPage: (e: DataTablePageEvent) => {
      firstVal.value = e.page
      updateList(false)
    },
    onSort: (e: DataTableSortEvent) => {
      sortFieldFilter.value = e.sortField as string
      sortOrderFilter.value = e.sortOrder as number
      updateList()
    },
    updateFilterListened,
    saveFilters,
    showConfirmDeleteFilter,
    cleanAndUpdateFiltersBySelector,
    updateSearchTxt,
    selectedColumns,
    allColumns,
    changeSelectedColumns,
    loadingPaginator,
    countError
  }
}


import { Column } from '@/models/components/tables/Column'
import { ColumnFields, StudentsService } from '@/models/components/tables/enums'
import CountrySelector from '@/components/courses/selectors/CountrySelector.vue'
import CarrerinoFilter from '../components/CarrerinoFilter.vue'
import CategoryFilter from '../components/CategoryFilter.vue'
import ServicesFilter from '../components/ServicesFilter.vue'
import { Country } from '@/models/countries/classes/Country'
import { EducationStudentsReponse } from '@/models/education-students/classes/education-student-response'
import { defineComponent, onMounted, PropType, ref, onBeforeMount, toRefs, watch } from 'vue'
import { EducationStudents, StudentSubscriptions } from '@/models/education-students/classes/education-student'
import { FilterMatchMode } from 'primevue/api'
import { EducationStudentAPI } from '@/api/education-student.api'
import router from '@/router'
import EmailSending from '@/components/modals/EmailSending.vue'
import { CommonRouteNames } from '@/router/route-names'
import { CoursePerformanceFilters, ICoursePerformanceFilters } from '@/models/custom-filters/filters/CoursePerformanceFilters'
import { CoursePlan } from '@/models/academic-definitions/classes/CoursePlan'
import { CoursePerformanceFiltersProps } from '@/models/custom-filters/filters/enums'
import { SetMail } from '@/models/email-sending/SetMail'
import { ProfileEducation } from '@/models/education-students/classes/education-student-profile'

export default defineComponent({
  props: {
    isNps: {
      type: Boolean,
      default: false
    },
    showCol: {
      type: Array as PropType<string[]>,
      required: true
    },
    students: {
      type: Array as PropType<EducationStudents[]>,
      default: () => []
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    allCoursePlans: {
      type: Array as PropType<CoursePlan[]>,
      required: true
    },
    recordsPerPage: {
      type: Number,
      default: 10
    },
    firstRecord: {
      type: Number,
      default: 0
    },
    totalRecords: {
      type: Number,
      detault: 0
    },
    customFilterActive: {
      type: Object as PropType<CoursePerformanceFilters>,
      require: false
    },
    allColumns: {
      type: Map as PropType<Map<ColumnFields, Column>>,
      require: true
    },
    selectedColumns: {
      type: Array as PropType<ColumnFields[]>,
      require: true
    }
  },
  components: {
    CountrySelector,
    EmailSending
    /* CarrerinoFilter,
    ServicesFilter */
  },
  emits: ['updateFilters'],
  setup (props, { emit }) {
    const { allColumns, selectedColumns, students: studentsRef, customFilterActive } = toRefs(props)
    const columns:Column[] = [
      { header: 'Nombre', field: ColumnFields.StudentsName },
      { header: 'Comisiones activas', field: ColumnFields.StudentsAComissions },
      { header: 'Carrerino', field: ColumnFields.StudentsCarrerino },
      { header: 'Categoría', field: ColumnFields.StudentsCategory },
      { header: 'Servicios activos', field: ColumnFields.StudentsAService }
    ]
    const filters = ref<ICoursePerformanceFilters>({
      countries: { value: null },
      startMonth: { value: null },
      courseType: { value: null },
      coursePlans: { value: null },
      columns: { value: null },
      carrerino: { value: null },
      categories: { value: null },
      services: { value: null }
    } as ICoursePerformanceFilters)
    const matchFilterWithCustomFilterActive = () => {
      filters.value.countries.value = customFilterActive.value?.countries.value ?? null
      filters.value.startMonth.value = customFilterActive.value?.startMonth.value ? new Date(customFilterActive.value?.startMonth.value as string) : null
      filters.value.courseType.value = customFilterActive.value?.courseType.value ?? null
      filters.value.coursePlans.value = customFilterActive.value?.coursePlans.value ?? null
      filters.value.columns.value = customFilterActive.value?.columns.value
        ? customFilterActive.value?.columns.value
        : allColumns.value
          ? Array.from(allColumns.value.keys())
          : null
    }
    const selectedRows = ref<EducationStudents[]>([])

    onBeforeMount(matchFilterWithCustomFilterActive)
    watch(selectedColumns, matchFilterWithCustomFilterActive)

    const updateFilters = (f: ICoursePerformanceFilters): void => {
      emit('updateFilters', f)
    }

    const cleanFilters = (fp: CoursePerformanceFiltersProps[]): void => {
      fp.map(p => {
        filters.value[p].value = null
        return 0
      })
      emit('updateFilters', filters.value)
    }
    const categoryOptions = ref<any[]>(
      [
        {
          name: StudentSubscriptions.CODERBECA,
          key: StudentSubscriptions.CODERBECA
        },
        {
          name: StudentSubscriptions.STANDARD,
          key: StudentSubscriptions.STANDARD
        },
        {
          name: StudentSubscriptions.CODERPLUS,
          key: StudentSubscriptions.CODERPLUS
        }
      ]
    )
    const servicesOptions = ref<any[]>(
      [
        {
          name: StudentsService.CV,
          key: StudentsService.CV
        },
        {
          name: StudentsService.LINKEDIN,
          key: StudentsService.LINKEDIN
        },
        {
          name: StudentsService.PORTFOLIO,
          key: StudentsService.PORTFOLIO
        },
        {
          name: StudentsService.STRATEGY,
          key: StudentsService.STRATEGY
        },
        {
          name: StudentsService.INTERVIEW,
          key: StudentsService.INTERVIEW
        },
        {
          name: StudentsService.PROPOSAL,
          key: StudentsService.PROPOSAL
        }
      ]
    )
    const carrerinoOptions = ref<any[]>(
      [
        {
          name: 'Si',
          key: true
        },
        {
          name: 'No',
          key: false
        }
      ]
    )
    const emailModal = ref<boolean>(false)
    const emailInfo = ref<SetMail>()
    const userName = ref<string>('')
    const showEmailModal = (info : any) => {
      userName.value = `${info.data.profile.first_name} ${info.data.profile.last_name}`
      emailInfo.value = {
        userId: info.data._id,
        country: info.data.profile.country,
        toMail: info.data.email
      }
      emailModal.value = !emailModal.value
    }
    return {
      emailModal,
      showEmailModal,
      studentsRef,
      updateFilters,
      cleanFilters,
      CoursePerformanceFiltersProps,
      filters,
      columns,
      ColumnFields,
      categoryOptions,
      Country,
      carrerinoOptions,
      servicesOptions,
      selectedRows,
      emailInfo,
      userName
    }
  }
})
